.section-profile {

  .header {
    .layout-profile {
      position: relative;
      margin-top: 25px;
      color: #fff;
      .btn-setting {
        position: absolute;
        right: 0;
        top: 14px;
        width: 19px;
        height: 19px;
      }
      .badges .item {
        color: #592800;
        background-color: #ebfbff;
        &:first-child {
          background-color: #fff4eb
        }
      }
    }
  }

  .result {
    .btn-search {
      position: absolute;
      right: 24px;
      top: 24px;
      background-image: url('../../assets/images/magnifying-glass-dark.png');
    }
  }

  .result-title {
    font-size: 16px;
    font-family: "BrandonText-Bold";
    letter-spacing: .63px;
    color: #06080c;
    margin-bottom: 24px
  }

  
}


.profile-wrapper {
  max-width: 414px;
  margin: auto;
  .profile-header {
    position: relative;
    text-align: center;
    background-image: linear-gradient(315deg, #ffab69 0%, #ff882b 100%);
    padding-bottom: 80px;
    padding-top: 10px;
    .title {
      margin-top: 10px;
      font-family: 'BrandonText-Bold';
      font-size: 16px;
      color: white;
    }
    .photo {
      width: 120px;
      height: 120px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      border-radius: 200px;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -50%);
      border: solid 3px white;
      -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .profile-body {
    margin-top: 50px;
    width: 100%;
    padding: 50px 20px;
    font-size: 12px;
    font-family: 'BrandonText-Medium';
    color: #535761;
    .profile-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 17px 0;
      &:not(:last-of-type) {
        border-bottom: 1px solid $color--grey-soft;
      }
      span {
        width: max-content;
      }
    }

    input.value {
      border: none;
      outline: none;
      background-color: transparent;
      text-align: right;
    }
  }
  .btn-edit {
    display: block;
    width: calc(100% - 40px);
    background-color: #005e74;
    font-size: 14px;
    padding: 15px;
    border-radius: 10px;
    color: white;
    text-align: center;
    margin: auto;
    font-family: 'BrandonText-Medium';

    &.logout {
      background-color: transparent;
      color: red;
    }
  }

  .btn-upload {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    // background-image: url(../images/icon-camera.png);
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color--grey-soft;
    border-radius: 100%;
    opacity: 0.75;
  }
  .field-upload {
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
    position: absolute;
  }
}
