.section-login {
  text-align: center;
  padding-top: 30px;
  display: flex;
  justify-content: center;

  .logo {
    width: 130pt;
  }

  .title {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    font-family: "BrandonText-Regular";
    margin-bottom: 30px;
  }

  .input-container {
    display: block ruby;
    width: 100%;
    margin-bottom: 15px;
  }

  .input-container.has-inline {
    display: block ruby;
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }

  .input-container > .password__show {
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: default;
  }

  .form {
    max-width: 300px;
    margin: auto;
  }

  .link {
    font-size: 13px;
    font-weight: 500;
    font-family: "BrandonText-Medium";
    letter-spacing: 0.3px;
    float: right;
  }

  .response {
    color: #005e74;
    font-size: 13px;
  }

  &--forgot_password .title {
    margin-bottom: 15px;
    margin-top: 50px;
  }

  &--forgot_password .subtitle {
    font-size: 13px;
    margin-bottom: 20px;
  }
}
