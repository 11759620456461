.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba($color: #000000, $alpha: 0);
  pointer-events: none;
  transition-duration: 0.3s;

  &.active {
    pointer-events: auto;
    background-color: rgba($color: #000000, $alpha: 0.7);

    .content {
      transform: translateY(0%);
    }
  }

  .content {
    transition-duration: 0.3s;
    transform: translateY(100%);
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 20px;
    background-color: white;
    padding-top: 70px;
    color: #06080c;
    .btn-close {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      font-family: 'BrandonText-Medium';
      // margin-bottom: 10px;
    }
    .scroll {
      overflow: auto;
      max-height: 45vh;
    }
    p {
      font-size: 15px;
      margin-bottom: 20px;
      color: #535761;
    }
    .btn-next {
      margin: auto;
      margin-top: 50px;
    }
  }
}
