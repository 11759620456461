
@font-face {
  font-family: "BrandonText-Medium";
  src: url("./BrandonText/BrandonText-Medium.woff2") format("woff2"),url("./BrandonText/BrandonText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: "BrandonText-Regular";
  src: url("./BrandonText/BrandonText-Regular.woff2") format("woff2"),url("./BrandonText/BrandonText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "BrandonText-Bold";
  src: url("./BrandonText/BrandonText-Bold.woff2") format("woff2"),url("./BrandonText/BrandonText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal
}